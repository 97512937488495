.layer-switcher.shown.ol-control {
    background-color: transparent;
}

.layer-switcher.shown.ol-control:hover {
    background-color: transparent;
}

.layer-switcher {
    position: absolute;
    top: 3.5em;
    right: 0.5em;
    text-align: left;
}

.layer-switcher.shown {
    bottom: 3em;
}

.layer-switcher .panel {
    padding: 0 1em 0 0;
    margin: 0;
    border: 4px solid #eee;
    border-radius: 4px;
    background-color: white;
    display: none;
    max-height: 100%;
    overflow-y: auto;
}

.layer-switcher.shown .panel {
    display: block;
}

.layer-switcher button {
    float: right;
    width: 38px;
    height: 38px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==') /*logo.png*/;
    background-repeat: no-repeat;
    background-position: 2px;
    background-color: white;
    border: none;
}

.layer-switcher.shown button {
    display: none;
}

.layer-switcher button:focus, .layer-switcher button:hover {
    background-color: white;
}

.layer-switcher ul {
    padding-left: 2em;
    list-style: none;
}

.layer-switcher li.group > label {
    font-weight: bold;
}

.layer-switcher li.layer {
    display: table;
}

.layer-switcher li.layer label, .layer-switcher li.layer input {
    display: table-cell;
    vertical-align: middle;
}

.layer-switcher label.disabled {
    opacity:0.4;
}

.layer-switcher input {
    margin: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar {
    width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.layer-switcher .group button {
    display: inline-block;
    vertical-align: top;
    float: none;
    width: 16px;
    height: 16px;
    background-position: center 2px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -ms-transition: -ms-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
}

.layer-switcher .group.layer-switcher-close button {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

li.group.layer-switcher-fold {
    margin-left: -18px;
}

.layer-switcher .group.layer-switcher-fold > ul {
    padding-left: 3em;
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
    overflow: hidden;
    height: 0;
}
